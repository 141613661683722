code {
    white-space: pre-wrap;
}

span.smallcaps {
    font-variant: small-caps;
}

span.underline {
    text-decoration: underline;
}

.privacy-text {
    display: inline-block;
    vertical-align: top;
    width: 65%;
    margin: 0 auto;
    padding-bottom: 50px;
}