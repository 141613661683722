img {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));

}

.downloads-row img {
    height: 80px;
    width: 80px;
}

.stats-row {
    min-height: 100px;
}

.jumbotron {
    color: Blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
}

.all1 {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
}
