.navbar { background-color:#ffffff; }

.left-token-logo { float: left; width: 50px; height: 50px; background-color: #5de1f3ff; }

.right-cash-logo { float: left; width: 50px; height: 50px; background-color: #54e9b7ff; }

.nav-link , .navbar-brand{ cursor: pointer;}

.nav-link{ margin-right: 1em !important;}

.nav-link:hover{ background: #f4f4f4; color: #f97300; }

.navbar-collapse{ justify-content: flex-end;}

.navbar-toggler{  background:#fff !important;}

.logo-container { width: 100px; height: 40px; }

.logo-container img {margin-top: 7px; max-width: 40px;
    max-height: 40px; display:block; position: absolute;
}

.logo-div { margin: 0 auto; width: 40px; height: 40px;}