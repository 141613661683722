.stats {
    margin-top: 20px;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    background-color: whitesmoke;
}

.stats-icons {
    max-height: 120px;
    max-width: 120px;
}