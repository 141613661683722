.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 120px;
    background-color: white;
    font-size: xx-small;
}

.footer ul {
    list-style-type: none;
}