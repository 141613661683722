


.btn {
    border-radius: 4px;
    background-color: #5de1f3;
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    width: 360px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

.btn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.btn:hover span {
    padding-right: 25px;
}

.btn:hover span:after {
    opacity: 1;
    right: 0;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*


.btn {
    width: 200px;
    height: 60px;
    border: 1px solid #333;
    font-family: 'Cinzel', serif;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    transition: 1s;
}

.btn::before, .btn::after {
    position: absolute;
    background: #5de1f3;
    z-index: -1;
    transition: 1s;
    content: '';
}

.btn::before {
    height: 60px;
    width: 380px;
}

.btn::after {
    width: 400px;
    height: 40px;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn:hover::before {
    width: 0px;
    background: #fff;
}

.btn:hover::after {
    height: 0px;
    background: #fff;
}

.btn:hover {
    background: #54e9b7;
}

*/
